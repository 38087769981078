import Image from 'next/image';
import { FC } from 'react';

import Modal from '@components/controls/Modal';

import { Button, scale } from '@scripts/gds';
import { useMedia } from '@scripts/hooks';

import registrationMiddleImage from '../../icons/shopping_dog_middle.png';
import registrationSmallImage from '../../icons/shopping_dog_small.png';

interface RegistrationPopupProps {
    isOpen: boolean;
    onClose: () => void;
    onChangeToRegistration: () => void;
}

export const RegistrationPopup: FC<RegistrationPopupProps> = ({ isOpen, onClose, onChangeToRegistration }) => {
    const { xs } = useMedia();
   
    return (
        <>
            <Modal
                css={{
                    padding: '16px 48px 24px 24px',
                    
                    height: '143px',
                    width: '328px',
                    position: 'absolute',
                    top: 181,
                    right: 'calc(50% - 590px)',
                    backgroundColor: '#FFF',
                    color: '#4A5661',
                    boxShadow: '0px 0px 5px 0px #00000040',
                    borderRadius: '20px',
                    zIndex: 12,
                    [xs]: {
                        width: '100%',
                        right: 0,
                        height: '80%',
                        padding: '16px 24px 24px 24px',
                        position: 'fixed'
                    },
                }}
                closemodalbuttonsize={9}
                title={' '}
                closeBtn
                onClose={onClose}
                isNewModal
            >
                <div
                    css={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        [xs]: { flexDirection: 'column', alignItems: 'center' },
                    }}
                >
                    <div>
                        <div
                            css={{
                                
                                fontSize: '14px',
                                fontWeight: 500,
                                lineHeight: '22.4px',
                                marginBottom: '16px',
                                [xs]: {
                                    width: '250px',
                                    textAlign: 'center',
                                    fontSize: '20px',
                                    lineHeight: '24px',
                                   
                                },
                            }}
                        >
                            Зарегистрируйся
                            <br css={{ [xs]: { display: 'none' } }} /> и получи цену ниже
                        </div>
                        <div
                            css={{
                                [xs]: {
                                    display: 'none',
                                },
                            }}
                        >
                            <Button
                                block
                                onClick={onChangeToRegistration}
                                theme="secondary"
                                size="sm"
                                css={{
                                    padding: `${scale(1)}px ${scale(2)}px !important`,
                                }}
                                data-testid="button-registration"
                            >
                                К регистрации
                            </Button>
                        </div>
                    </div>
                    <div css={{ [xs]: { display: 'none' } }}>
                        <Image
                            src={registrationSmallImage}
                            alt=""
                            width={0}
                            height={0}
                            sizes="100vw"
                            style={{ width: '100px', height: '95px' }}
                        />
                    </div>
                    <div css={{ display: 'none', [xs]: { display: 'block', marginTop: '-4px', marginBottom: '6px' } }}>
                        <Image
                            src={registrationMiddleImage}
                            alt=""
                            width={0}
                            height={0}
                            sizes="100vw"
                            style={{ width: '160px', height: '151px' }}
                        />
                    </div>
                    <div
                        css={{
                            display: 'none',
                            [xs]: {
                                display: 'block',
                                width: '100%',
                            },
                        }}
                    >
                        <Button
                            block
                            onClick={onChangeToRegistration}
                            theme="secondary"
                            size="sm"
                            css={{
                                padding: `${scale(1)}px ${scale(2)}px !important`,
                                [xs]: {
                                    padding: `14px 0 !important`,
                                    
                                    width: '100% !important',
                                    fontSize: '16px',
                                    fontWeight: 500,
                                    lineHeight: '19.2px',
                                    letterSpacing: '0.018em',
                                },
                            }}
                            data-testid="button-registration"
                        >
                            К регистрации
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};
